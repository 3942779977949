<template>
  <CForm ref="form" class="needs-validation">
    <CInput
        label="Telefon: "
        horizontal
        type="text"
        v-model="data.customerPhoneNumber"
        required
    />
  </CForm>
</template>

<script>
export default{
  name: "CargoPhoneForm",
  props:{
    validated: Boolean,
    params: Object,
    actionType: String,
  },
  watch:{
    params: function(val){
      this.data = {...val};
    },
    actionType:  function(val){
      if(val == 'create'){
        this.data = {...this.params};
      }
    },
  },
  data: function(){
    return{
      data: this.params ? {...this.params} : {},

    }
  }
}
</script>